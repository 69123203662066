import { Link, useLocation } from 'react-router-dom';
import gosu from '../images/gosu.png';

const SideBar = () => {
    const router = useLocation();

    return (
        <div className="sidebar">
            <div className="page">
                <a href="/" className="logo-link">
                    <h4>License manager</h4>
                </a>
                <Link
                    className={`sidbar-btn ${router.pathname.includes('/tsd') ? 'sidbar-btn--active' : ''}`}
                    to="/tsd"
                >
                    TSD
                </Link>
                {router.pathname.includes('/tsd')}
                <Link
                    className={`sidbar-btn ${router.pathname.includes('/arca') ? 'sidbar-btn--active' : ''}`}
                    to="/arca"
                >
                    ARCA
                </Link>
                {router.pathname.includes('/tinda')}
                <Link
                    className={`sidbar-btn ${router.pathname.includes('/tinda') ? 'sidbar-btn--active' : ''}`}
                    to="/tinda"
                >
                    TINDA
                </Link>
                {router.pathname.includes('/iiko')}
                <Link
                    className={`sidbar-btn ${router.pathname.includes('/iiko') ? 'sidbar-btn--active' : ''}`}
                    to="/iiko"
                >
                    IIKO
                </Link>
            </div>

            <div style={{ marginBottom: '0px' }}>
                <p style={{ color: '#838383' }}>
                    Powered by <img src={gosu} alt="" style={{ height: '20px', marginTop: '10px' }} />
                </p>
            </div>
        </div>
    );
};

export default SideBar;

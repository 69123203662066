import getAxiosInstance from "../Axios/axios";
import moment from "moment";

const instance = getAxiosInstance("1");

const data = {
  username: "bridgeadmin",
  password: "vbnJ456#",
  deviceID: "string",
  deviceName: "string",
  role: "string",
};

async function getJwtToken() {
  try {
    const res = await instance.post(`api/Auth/login`, data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const TsdAPI = {
  async getUsers() {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.get(`api/Admin/GetUsers`, { headers });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async getIsExist() {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.get(
        `api/Admin/GetUsers?username=${data.username}`,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async createUser(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.post(`api/Auth/register`, data, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async setPass(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.patch(
        `api/Auth/changePassword?username=${data.username}&password=${data.password}`,
        null,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async patchUser(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.patch(
        `api/Admin/SetDeviceCount?username=${data.username}&countdevice=${data.devicecount}`,
        null,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async setBin(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.patch(
        `api/Admin/SetBIN?username=${data.username}&bin=${data.bin}`,
        null,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async setOrg(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.patch(
        `api/Admin/SetOrg?username=${data.username}&org=${data.org}`,
        null,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async setExpireDate(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const formattedExpireDate = new Date(data.expireDate);
      const formattedDateString = `${formattedExpireDate.getFullYear()}-${(formattedExpireDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${formattedExpireDate.getDate().toString().padStart(2, '0')}T${formattedExpireDate
          .toTimeString()
          .slice(0, 8)}`;

      const response = await instance.patch(
        `api/Admin/SetExpireDate?username=${data.username}&expireDate=${formattedDateString}`,
        null,
        { headers }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getUserDetail(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.get(
        `api/Admin/GetUserInfo?username=${data.username}`,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteUser(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await instance.delete(
        `api/Admin/DeleteUser?username=${data.username}`,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async activeUser(data) {
    try {
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`
      }
      const response = await instance.patch(
        `api/Admin/SetActiveUser?username=${data.username}`,
        null,
        { headers }
      );
      return response.data
    } catch (error) {
      throw error
    }
  }
};

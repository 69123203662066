import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import authReducer, { authSlice } from "./slicers/AuthSlice";
import userReducer, { userSlice } from "./slicers/UserSlice";

// Combine your reducers here
const rootReducer = combineReducers({
  [authSlice.name]: authReducer,
  [userSlice.name]: userReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user"],
  // Other configuration options if needed
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };

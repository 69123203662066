import React, { useState } from "react";
import { TsdAPI } from "../../Api/tsdApi";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePassword,
  updateUsername,
  catchError,
} from "../../services/slicers/UserSlice";
import { LiaKeySolid } from "react-icons/lia";
import Popup from "reactjs-popup";

const ModalButtonTsd = () => {
  const initialData = {
    username: "",
    password: "",
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [newPassword, setNewPassword] = useState("");

  const openModal = () => {
    setIsOpen(true);
    dispatch(updateUsername(formData.username));
    setFormData({ ...formData, username: user.username || "" });
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormData(initialData);
    setNewPassword("");
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword.trim() === "") {
      alert('Поле "Новый Пароль" не может быть пустым');
      return;
    }

    try {
      await TsdAPI.setPass({
        username: formData.username,
        password: newPassword,
      });
      dispatch(updatePassword(newPassword));
      alert(`Изменен пароль для пользователя ${formData.username}`);
      closeModal();
    } catch (error) {
      console.error("Ошибка при изменении пароля:", error);
      dispatch(catchError());
    }
  };

  return (
    <div>
      <Popup
        trigger={
          <button className="button_add " onClick={openModal}>
            <LiaKeySolid color="white" size={"3em"} onClick={openModal} />
          </button>
        }
        modal
      >
        {(close) => (
          <div className="modall">
            <AiOutlineClose color="white" onClick={close} className="closeB"/>
            <form className="new_pass" onSubmit={handlePasswordChange}>
              <label>Новый пароль:</label>
              <input
                  type="text"
                  name="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              <button type="submit">Сохранить</button>
            </form>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ModalButtonTsd;

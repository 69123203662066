import React, { useEffect, useState } from "react";
import { LicenseAPI } from "../../Api/LicenseApi";
import ModalButtonArca from "../License/ModalButton";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import Form from "react-bootstrap/Form";
import { TbEdit } from "react-icons/tb";
import { FiSave } from "react-icons/fi";
import { TbReload } from "react-icons/tb";
import moment from "moment";
import CustomTooltip from "../Hints/CustomTooltip";

const ArcaTable = () => {
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(18);

  const [searchQuery, setSearchQuery] = useState("")

  const [editingOrg, setEditingOrg] = useState(null);
  const [editingBin, setEditingBin] = useState(null);
  const [editingExpireDate, setEditingExpireDate] = useState(null)

  const [orgValue, setOrgValue] = useState('');
  const [binValue, setBinValue] = useState('');
  const [expireDateValue, setExpireDateValue] = useState('')

  useEffect(() => {
    getFetchData();
  }, [binValue, orgValue]);

  const getFetchData = async () => {
    try {
      const res = await LicenseAPI.getLicense();
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOrgButtonClick = (mac) => {
    setEditingOrg(mac);
    setOrgValue(data.find(item => item.mac_address === mac).org)
  }

  const handleBinButtonClick = (mac) => {
    setEditingBin(mac)
    setBinValue(data.find(item => item.mac_address === mac).bin)
  }

  const handleExpireButtonClick = (mac) => {
    setEditingExpireDate(mac)
    setExpireDateValue(data.find(item => item.mac_address === mac).expire_date)
  }

  const handleActivateLicense = async (mac) => {
    try{
      await LicenseAPI.active({mac})
      getFetchData()
    } catch (error) {
      console.log("Ошибка", error)
    }
  }

  const handleSaveOrg = async (mac) => {
    try {
      await LicenseAPI.setOrg({ mac, org: orgValue })

      setEditingOrg(null)
    } catch (error) {
      console.log('Ошибка при изменении org:', error)
    }
  }

  const handleSaveExpireDate = async (mac) => {
    try {
      await LicenseAPI.setExpireDate({ mac, expireDate: expireDateValue })

      setEditingExpireDate(null)
    } catch (error) {
      console.log('Ошибка при изменении expireDate:', error)
    }
  }

  const handleSaveBin = async (mac) => {
    try {
      await LicenseAPI.setBin({ mac, bin: binValue })

      setEditingBin(null)
    } catch (error) {
      console.log('Ошибка при изменении bin:', error)
    }
  }

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };


  const filteredData = data.filter(
    (license) =>
      license.mac_address.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      license.org.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      license.bin.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <div className="licenseTable">
      <div className="table_option">
        <input
          className="search-input"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Поиск..."
        />
        <div className="items_per_page">
          Лицензий на страницу:
          <select
            className="items_count"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={18}>18</option>
            <option value={36}>36</option>
            <option value={54}>54</option>
          </select>
        </div>
        <CustomTooltip text="Создание лицензии">
          <ModalButtonArca />
        </CustomTooltip>
        <TbReload
          onClick={() => { getFetchData() }}
        />
      </div>
      <div className="cards table_body">
        {currentItems.map((item, index) => (
          <Form className="card" key={index}>
            <table className="user_details_table">
              <tbody>
                <tr>
                  <td>
                    <p>MAC-Address</p>
                  </td>
                  <td>
                    <p className="value">
                      {item.mac_address ? item.mac_address : "-"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Licences Key</p>
                  </td>
                  <td>
                    <p className="value">
                      {item.licences_key ? item.licences_key : "-"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Organization</p>
                  </td>
                  <td>
                    <p className="value">
                      {editingOrg === item.mac_address ? (
                        <>
                          <input type="text" value={orgValue} onChange={(e) => setOrgValue(e.target.value)} />
                          <button onClick={(e) => {
                            e.preventDefault()
                            handleSaveOrg(item.mac_address)
                          }}>
                            <FiSave />
                          </button>
                        </>
                      ) : (
                        <>
                          <span>{item.org || "N/A"}</span>
                          <button onClick={(e) => {
                            e.preventDefault()
                            handleOrgButtonClick(item.mac_address)
                          }}>
                            <TbEdit />
                          </button>
                        </>
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>BIN</p>
                  </td>
                  <td>
                    <p className="value">
                      {editingBin === item.mac_address ? (
                        <>
                          <input type="text" value={binValue} onChange={(e) => setBinValue(e.target.value)} />
                          <button onClick={(e) => {
                            e.preventDefault();
                            handleSaveBin(item.mac_address)
                          }}>
                            <FiSave />
                          </button>
                        </>
                      ) : (
                        <>
                          <span>{item.bin || "N/A"}</span>
                          <button onClick={(e) => {
                            e.preventDefault();
                            handleBinButtonClick(item.mac_address)
                          }}>
                            <TbEdit />
                          </button>
                        </>
                      )}
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Licences Date</p>
                  </td>
                  <td>
                    <p className="value">
                      {item.licences_date ? item.licences_date : "-"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Expire Date</p>
                  </td>
                  <td>
                    <p className="value">
                      {editingExpireDate === item.mac_address ? (
                        <>
                          <input type="date" value={expireDateValue} onChange={(e) => setExpireDateValue(e.target.value)} />
                          <button onClick={(e) => {
                            e.preventDefault();
                            handleSaveExpireDate(item.mac_address)
                          }}>
                            <FiSave />
                          </button>
                        </>
                      ) : (
                        <>
                          <span>{moment(item.expire_date).format("DD.MM.YYYY") || "N/A"}</span>
                          <button onClick={(e) => {
                            e.preventDefault();
                            handleExpireButtonClick(item.mac_address)
                          }}>
                            <TbEdit />
                          </button>
                        </>
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Status:</p>
                  </td>
                  <td>

                    <div
                      className={`statusChangeBtn ${item.status ? "ActiveSBtn" : "DeactiveSBtn"}`}
                      onClick={() => handleActivateLicense(item.mac_address)}
                    >
                      {item.status ? "Active" : "Deactive"}
                    </div>

                  </td>
                </tr>

              </tbody>
            </table>
          </Form>
        ))}
      </div>

      <div className="pagination">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LiaAngleLeftSolid />
        </button>
        {Array.from({
          length: Math.ceil(data.length / itemsPerPage),
        }).map((_, page) => (
          <span
            key={page}
            onClick={() => goToPage(page + 1)}
            className={currentPage === page + 1 ? "active" : ""}
          >
            {page + 1}
          </span>
        ))}
        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        >
          <LiaAngleRightSolid />
        </button>
      </div>
    </div>
  );
};

export default ArcaTable;

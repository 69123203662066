import { useState } from "react"
import { IikoAPI } from "../../Api/iikoAPI"
import Popup from "reactjs-popup";
import { LiaUserPlusSolid, LiaKeySolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";

const ModalButtonOrgIiko = () => {
    const initData = {
        name: ""
    }

    const [isOpen, setIsOpen] = useState(false)
    const [formData, setFormData] = useState(initData)

    const closeModal = () => {
        setIsOpen(false)
        setFormData(initData)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        IikoAPI.createOrg(formData);
        closeModal();
    };

    return (
        <div>
            <Popup
                trigger={
                    <button className="button_add ">
                        <LiaUserPlusSolid className="btn_logo" />
                    </button>
                }
                nested
                modal
            >
                {(close) => (
                    <div className="modall addUser arka">
                        <AiOutlineClose color="white" onClick={close} className="closeB" />
                        <form className="new_user" onSubmit={handleSubmit}>
                            <h4>Новая организация</h4>
                            <label>Name:</label>
                            <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
                            <button type="sumbit" className="sumbit">
                                Сохранить
                            </button>
                        </form>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default ModalButtonOrgIiko
import { useEffect, useState } from "react";
import { IikoAPI } from "../../Api/iikoAPI";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import { Link } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import ModalButtonLicenseIiko from "./IikoLicenseModel";
import ModalButtonOrgIiko from "./IikoOrgModel";
import moment from "moment";
import { TbReload } from "react-icons/tb";
import CustomTooltip from "../Hints/CustomTooltip";

const IikoTable = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(18);

    useEffect(() => {
        getFetchData();
        const interval = setInterval(() => getFetchData(), 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const getFetchData = async () => {
        try {
            const res = await IikoAPI.getLicense()
            setData(res)
        } catch (error) {
            console.error(error)
        }
    }

    const goToPage = (page) => {
        setCurrentPage(page);
    };
    const nextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handleActivateLicense = async (id) => {
        try{
            const confurmed = window.confirm(
                "Вы уверены, что хотите активировать лицензию?"
            )
            if (confurmed) {
                await IikoAPI.activateLicense({ id })
                getFetchData()
            }
        } catch (error) {
            console.log("Error", error)
        }
    }

    const handleDeactivateLicense = async (id) => {
        try{
            const confurmed = window.confirm(
                "Вы уверены, что хотите деактивировать лицензию?"
            )
            if (confurmed) {
                await IikoAPI.deactivateLicense({ id })
                getFetchData()
            }
        } catch (error) {
            console.log("Error", error)
        }
    }

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="licenseTable">
            <div className="table_option">
                <div className="table_option_right">
                    <div className="items_per_page">
                        Лицензий на страницу:
                        <select
                            className="items_count"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            <option value={18}>18</option>
                            <option value={36}>36</option>
                            <option value={54}>54</option>
                        </select>
                    </div>
                    <CustomTooltip text="Создание лицензии">
                        <ModalButtonLicenseIiko />
                    </CustomTooltip>

                    <CustomTooltip text="Создание организации">
                        <ModalButtonOrgIiko />
                    </CustomTooltip>

                    <Link
                        to="/iiko-logs"
                    >
                        Перейти к логам
                    </Link>
                    <TbReload
          onClick={() => {getFetchData()}}
        />
                </div>
            </div>
            <div className="cards table_body">
                {currentItems.map((item, index) => (
                    <Form className="card" key={index}>
                        <table className="user_details_table">
                            <tr>
                                <td>
                                    <p>Product Name</p>
                                </td>
                                <td>
                                    <p className="value">
                                        {item.license.productName ? item.license.productName : "-"}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Organization</p>
                                </td>
                                <td>
                                    <p className="value">
                                        {item.license.organization.name ? item.license.organization.name : "-"}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>License Code</p>
                                </td>
                                <td>
                                    <p className="value" onClick={(event) => {
                                        event.stopPropagation();
                                        navigator.clipboard.writeText(item.license.licenseCode ? item.license.licenseCode : "-");
                                    }}>
                                        {item.license.licenseCode ? item.license.licenseCode.slice(0, 10) + "..." : "-"}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Application Id</p>
                                </td>
                                <td>
                                    <p className="value">
                                        {item.license.apUId ? item.license.apUId : "-"}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Application Request</p>
                                </td>
                                <td>
                                    <p className="value">
                                        {item.license.apRequest ? item.license.apRequest : "-"}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Create Date</p>
                                </td>
                                <td>
                                    <p className="value">
                                        {moment(item.license.generateDate).format("DD.MM.YYYY") ===
                                            "Invalid date"
                                            ? item.license.generateDate
                                            : moment(item.license.generateDate).format("DD.MM.YYYY")}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Last Request Date</p>
                                </td>
                                <td>
                                    <p className="value">
                                        {moment(item.license.lastRequestDate).format("DD.MM.YYYY") ===
                                            "Invalid date"
                                            ? item.license.lastRequestDate
                                            : moment(item.license.lastRequestDate).format("DD.MM.YYYY")}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>license Expiration Date:</p>
                                </td>
                                <td>
                                    <p className="value">
                                    {moment(item.license.licenseExpirationDate).format("DD.MM.YYYY") ===
                                            "Invalid date"
                                            ? item.license.licenseExpirationDate
                                            : moment(item.license.licenseExpirationDate).format("DD.MM.YYYY")}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Status</p>
                                </td>
                                <td>
                                    <div 
                                        className={`statusChangeBtn ${item.license.isActive ? "ActiveSBtn" : "DeactiveSBtn"}`}
                                        onClick={() => item.license.isActive ? handleDeactivateLicense(item.license.id) : handleActivateLicense(item.license.id) }
                                    >
                                        {item.license.isActive ? "Active" : "Deactive"}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </Form>
                ))}
            </div>
            <div className="pagination">
                <button
                    onClick={() => goToPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <LiaAngleLeftSolid />
                </button>
                {Array.from({
                    length: Math.ceil(data.length / itemsPerPage),
                }).map((_, page) => (
                    <span
                        key={page}
                        onClick={() => goToPage(page + 1)}
                        className={currentPage === page + 1 ? "active" : ""}
                    >
                        {page + 1}
                    </span>
                ))}
                <button
                    onClick={nextPage}
                    disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                >
                    <LiaAngleRightSolid />
                </button>
            </div>
        </div>
    )
}

export default IikoTable
import { useState } from "react";
import { setAuth } from "../services/slicers/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';


const LoginModal = () => {

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();

  const initialData = {
    username: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const correctUsername = "admin";
    const correctPassword = "admin";

    if (
      formData.username === correctUsername &&
      formData.password === correctPassword
    ) {
      localStorage.setItem("login", formData.username);
      dispatch(setAuth(true));
      console.log(isAuth);
      handleClose();
    } else {
      alert("Неверное имя пользователя и/или пароль");
    }

    console.log("Form Data:", formData);
  };
  return (
    <div>
      {isAuth === false && (
        <Modal show={show} >
        <Modal.Header >
          <Modal.Title>Authorization</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-4">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Login</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Login"
                value={formData.username}
                onChange={handleInputChange}
                name="username"
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput2"> 
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                name="password" 
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="primary" size="lg" onClick={handleSubmit}>
            Log-in
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </div>
  );
};

export default LoginModal;

import React, { useEffect, useState } from "react";
import { TsdAPI } from "../../Api/tsdApi";
import { Link } from "react-router-dom";
import ModalButtonTsd from "../TSD/ModalButton";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { TbReload } from "react-icons/tb";
import CustomTooltip from "../Hints/CustomTooltip";

const TsdTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getDataFetch();
    const interval = setInterval(() => getDataFetch(), 5000);
    return () => clearInterval(interval);
  }, []);

  const getDataFetch = () => {
    TsdAPI.getUsers().then((res) => setData(res));
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleDeactivateLicense = async (username) => {
    try {
      const confurmed = window.confirm(
        "Вы уверены, что хотите деактивировать лицензию?"
      )
      if (confurmed) {
        await TsdAPI.activeUser({ username })
        getDataFetch()
      }
    } catch (error) {
      console.log("Error deactivate license:", error)
    }
  }

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const filteredData = data.filter(
    (user) =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      user.org.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      user.bin.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="licenseTable">
      <div className="table_option">
        <input
          className="search-input"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Поиск..."
        />
        <div className="table_option_right">
          <div className="items_per_page">
            Лицензий на страницу:
            <select
              className="items_count"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={18}>18</option>
              <option value={36}>36</option>
              <option value={54}>54</option>
            </select>
          </div>
          <CustomTooltip text='Создание лицензии'>
           <ModalButtonTsd />
          </CustomTooltip>

          <TbReload
          onClick={() => {getDataFetch()}}
        />
        </div>
      </div>
      <div className="cards table_body">
        {currentItems.map((user, index) => (
          <Form className="card" key={index}>

              <table className="user_details_table">
                <tbody>
                  <tr>
                    <td>
                      <p>Username</p>
                    </td>
                    <td>
                      <p className="value">
                        {user.username ? user.username : "-"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Role</p>
                    </td>
                    <td>
                      <p className="value">{user.role ? user.role : "-"}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Organization</p>
                    </td>
                    <td>
                      <p className="value">{user.org ? user.org : "-"}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Available Device Count</p>
                    </td>
                    <td>
                      <p className="value">
                        {user.availableDeviceCount
                          ? user.availableDeviceCount
                          : "-"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>BIN</p>
                    </td>
                    <td>
                      <p className="value">{user.bin ? user.bin : "-"}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Register Date</p>
                    </td>
                    <td>
                      <p className="value">
                        {user.registerDate ? user.registerDate : "-"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Expire Date</p>
                    </td>
                    <td>
                      <p className="value">
                        {moment(user.expireDate).format("DD.MM.YYYY") ===
                          "Invalid date"
                          ? user.expireDate
                          : moment(user.expireDate).format("DD.MM.YYYY")}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Status:</p>
                    </td>
                    <td>
                      <div
                        className={`statusChangeBtn ${user.isActive ? "ActiveSBtn" : "DeactiveSBtn"}`}
                        onClick={() => handleDeactivateLicense(user.username)}
                      >

                        {user.isActive ? "Active" : "Deactive"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Details:</p>
                    </td>
                    <td>
                      <Link to={`/tsd-detail/${user.username}`}>
                        <div className="statusChangeBtn ActiveSBtn">
                          Fail
                        </div>  
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
          </Form>
        ))}
      </div>

      <div className="pagination">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LiaAngleLeftSolid />
        </button>
        {Array.from({
          length: Math.ceil(filteredData.length / itemsPerPage),
        }).map((_, page) => (
          <span
            key={page}
            onClick={() => goToPage(page + 1)}
            className={currentPage === page + 1 ? "active" : ""}
          >
            {page + 1}
          </span>
        ))}
        <button
          onClick={nextPage}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
        >
          <LiaAngleRightSolid />
        </button>
      </div>
    </div>
  );
};

export default TsdTable;

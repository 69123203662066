import { createSlice } from "@reduxjs/toolkit";

// Типизация для состояния пользователя

// Начальное состояние
const initialState = {
  username: null,
  password: null,
  availableDeviceCount: null,
  role: null,
  bin: null,
  status: null,
  org: null,
  registerDate: null,
};

// Создание среза (slice) для пользователя
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //обновление имени пользователя
    updateUsername(state, action) {
      state.username = action.payload;
    },
    updatePassword(state, action) {
      state.password = action.payload;
    },
    catchError() {
      alert("Error write");
    },
    // Удаление имени пользователя
    deleteUsername(state) {
      state.username = null;
    },
  },
});

// Экспорт экшенов и селектора
export const { updateUsername, deleteUsername, updatePassword, catchError } =
  userSlice.actions;

// Селектор для получения имени пользователя из состояния
export const selectUser = (state) => state.user.username;

// Экспорт редьюсера
export default userSlice.reducer;

import { useState } from "react";
import { TindaApi } from "../../Api/TindaApi";
import { AiOutlineClose } from "react-icons/ai";
import Popup from "reactjs-popup";
import { LiaUserPlusSolid, LiaKeySolid } from "react-icons/lia";

const ModalButtonTinda = () => {
  const initialData = {
    username: "",
    password: "",
    org: "",
    bin: "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initialData);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormData(initialData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    TindaApi.createUser(formData);
    closeModal();
  };

  return (
    <div>
      <Popup
        trigger={
          <button className="button_add ">
            <LiaUserPlusSolid className="btn_logo" />
          </button>
        }
        nested
        modal
      >
        {(close) => (
          <div className="modall addUser arka">
            <AiOutlineClose color="white" onClick={close} className="closeB" />
            <form className="new_user" onSubmit={handleSumbit}>
              <h4>Новый пользователь</h4>
              <label>Логин пользователя:</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
              <label>Пароль пользователя:</label>
              <input
                type="text"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <label>Организация пользователя:</label>
              <input
                type="text"
                name="org"
                value={formData.org}
                onChange={handleInputChange}
              />
              <label>БИН пользователя:</label>{" "}
              <input
                type="text"
                name="bin"
                value={formData.bin}
                onChange={handleInputChange}
              />
              <button type="submit" className="sumbit">
                Сохранить
              </button>
            </form>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ModalButtonTinda;

import React, { useEffect, useState } from "react";
import { TsdAPI } from "../../Api/tsdApi";
import { useParams, useNavigate } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { FiSave } from "react-icons/fi";
import ChangePasswordModal from "./ChangePassword";
import moment from "moment";

import {
  updateUsername,
  deleteUsername,
  selectUser,
} from "../../services/slicers/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import { LiaReplySolid } from "react-icons/lia";
import { FiUserX } from "react-icons/fi";
import CustomTooltip from "../Hints/CustomTooltip";

const TSDLicenseDetail = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const { username } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getFetchData();
  }, []);

  const getFetchData = () => {
    TsdAPI.getUserDetail({ username: username }).then((res) => {
      setData(res);
      dispatch(updateUsername(username));
    });
  };

  const handleDeleteUser = () => {
    try {
      const confirmed = window.confirm("Вы уверены что хотите удалить?");
      dispatch(deleteUsername);
      if (confirmed) {
        TsdAPI.deleteUser({ username: username }).then((res) => {
          navigate("/tsd");
        });
      }
    } catch (error) {
      console.error("Error deleting license:", error);
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingBin, setIsEditingBin] = useState(false);
  const [isEditingDeviceCount, setIsEditingDeviceCount] = useState(false);
  const [isEditingExpireDate, setIsEditingExpireDate] = useState(false);

  const [editedOrg, setEditedOrg] = useState(data.org || "");
  const [editedBin, setEditedBin] = useState(data.bin || 0);

  const [editedDeviceCount, setEditedDeviceCount] = useState(
    data.availableDeviceCount || 0
  );
  const [editedExpireDate, setEditedExpireDate] = useState(data.expireDate || '');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditBinClick = () => {
    setIsEditingBin(true);
  };
  const handleEditDeviceCount = () => {
    setIsEditingDeviceCount(true);
  };
  const handleEditExpireDateClick = () => {
    setIsEditingExpireDate(true);
  };

  const setBin = async (username, editedBin) => {
    try {
      await TsdAPI.setBin({ username, bin: parseInt(editedBin) });
      console.log("setBin: Успешно установлен bin");
    } catch (error) {
      console.error("setBin: Ошибка при установке bin", error);
      throw error;
    }
  };

  const setOrg = async (username, editedOrg) => {
    try {
      await TsdAPI.setOrg({ username, org: editedOrg });
      console.log("setOrg: Успешно установлен Org");
    } catch (error) {
      console.error("setOrg: Ошибка при установке Org", error);
      throw error;
    }
  };
  const setExitedDate = async (username, editedExpireDate) => {
    try {
      await TsdAPI.setExpireDate({ username, expireDate: editedExpireDate });
      console.log("setExpireDate: Успешно установлен ExpireDate");
    } catch (error) {
      console.error("setExpireDate: Ошибка при установке ExpireDate", error);
      throw error;
    }
  };

  const patchUser = async (username, editedDeviceCount) => {
    try {
      await TsdAPI.patchUser({
        username,
        devicecount: parseInt(editedDeviceCount),
      });
      console.log("patchUser: Успешно обновлен devicecount");
    } catch (error) {
      console.error("patchUser: Ошибка при обновлении devicecount", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editedOrg) {
        await setOrg(data.username, editedOrg);
      }
      if (editedBin) {
        await setBin(data.username, editedBin);
      }

      if (editedDeviceCount) {
        await patchUser(data.username, editedDeviceCount);
      }
      
      if (editedExpireDate) {
        console.log(editedExpireDate)
        await setExitedDate(data.username, editedExpireDate);
      }

      getFetchData();

      setIsEditing(false);
      setIsEditingBin(false);
      setIsEditingDeviceCount(false);
      setIsEditingExpireDate(false);
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
    }
  };

  const handleInputChange = (e) => {
    setEditedOrg(e.target.value);
    setData({ ...data, org: e.target.value });
  };

  const handleBinChange = (e) => {
    setEditedBin(e.target.value);
    setData({ ...data, bin: e.target.value });
  };

  const handleDeviceCountChange = (e) => {
    setEditedDeviceCount(e.target.value);
    setData({ ...data, availableDeviceCount: e.target.value });
  };

  const handleExpireDateChange = (e) => {
    setEditedExpireDate(e.target.value);
    setData({ ...data, expireDate: e.target.value });
  };
  
  return (
    <div className="licenseTable">
      <div className="table_option">
        <LiaReplySolid
          size={"1.5em"}
          onClick={() => {
            navigate("/tsd");
          }}
        />

        <div className="table_option_right">
          <button className="button_add delete_u" onClick={handleDeleteUser}>
            <FiUserX className="btn_logo delete_user" />
          </button>
          <CustomTooltip text='Изменение пароля'>
            <ChangePasswordModal />
          </CustomTooltip>
        </div>
      </div>
      <div className="cardDetail">
        <table className="detail dleft">
          <thead>
            <tr>
              <th>Username:</th>
              <th>{data.username}</th>
            </tr>
          </thead>

          <tr>
            <td>Role:</td>
            <td>{data.role}</td>
          </tr>

          <tr>
            <td>Status:</td>
            <td>{data.isActive ? "Активно" : "Не активно"}</td>
          </tr>

          <tr>
            <td>Organization:</td>
            <td>
                {isEditing ? (
                  <input
                    className="input_editable"
                    type="text"
                    value={data.org}
                    onChange={handleInputChange}
                    autoFocus
                  />
                ) : (
                  <span>{data.org || "N/A"}</span>
                )}
                {isEditing ? (
                  <button className="button_edit" onClick={handleSubmit}>
                    
                  </button>
                ) : (
                  <button className="button_edit" onClick={handleEditClick}>
                    
                  </button>
                )}
            </td>
          </tr>

          <tr>
            <td>BIN:</td>
            <td>
                {isEditingBin ? (
                  <input
                    className="input_editable"
                    type="text"
                    value={data.bin}
                    onChange={handleBinChange}
                    autoFocus
                  />
                ) : (
                  <span>{data.bin || "N/A"}</span>
                )}
                {isEditingBin ? (
                  <button className="button_edit" onClick={handleSubmit}>
                    <FiSave />
                  </button>
                ) : (
                  <button className="button_edit" onClick={handleEditBinClick}>
                    <TbEdit />
                  </button>
                )}
            </td>
          </tr>

          <tr>
            <td>Available Device Count:</td>
            <td>
                {isEditingDeviceCount ? (
                  <input
                    className="input_editable"
                    type="number"
                    value={data.availableDeviceCount}
                    onChange={handleDeviceCountChange}
                    autoFocus
                  />
                ) : (
                  <span>{data.availableDeviceCount || "N/A"}</span>
                )}
                {isEditingDeviceCount ? (
                  <button className="button_edit" onClick={handleSubmit}>
                    <FiSave />
                  </button>
                ) : (
                  <button
                    className="button_edit"
                    onClick={handleEditDeviceCount}
                  >
                    <TbEdit />
                  </button>
                )}
            </td>
          </tr>

          <tr>
            <td>Register Date:</td>
            <td>{data.registerDate}</td>
          </tr>

          {data.lastTransactionDate && (
            <tr>
              <td>Last Transaction Date:</td>
              <td>{data.lastTransactionDate || "N/A"}</td>
            </tr>
          )}

          <tr>
            <td>Expaire date:</td>
            <td>
                {isEditingExpireDate ? (
                  <input
                    className="input_editable"
                    type="date"
                    value={data.expireDate}
                    onChange={handleExpireDateChange}
                    autoFocus
                  />
                ) : (
                  // {}
                  <span>{moment(data.expireDate).format("DD.MM.YYYY") ===
                  "Invalid date"
                    ? data.expireDate
                    : moment(data.expireDate).format("DD.MM.YYYY") || "N/A"}</span>
                )}
                {isEditingExpireDate ? (
                  <button className="button_edit" onClick={handleSubmit}>
                    <FiSave />
                  </button>
                ) : (
                  <button
                    className="button_edit"
                    onClick={handleEditExpireDateClick}
                  >
                    <TbEdit />
                  </button>
                )}
            </td>
          </tr>
          {data.rest? (
            <>
              <tr>
                <th>REST</th>
                <th>Information</th>
              </tr>

              <tr>
                <td>Name:</td>
                <td>{data.rest.name}</td>
              </tr>

              <tr>
                <td>ID:</td>
                <td>{data.rest.id}</td>
              </tr>

              <tr>
                <td>URI:</td>
                <td>{data.rest.uri}</td>
              </tr>

            </>
          ): null}
        </table>

        <table className="detail dright">
          <thead>
            <tr>
              <th>Name</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {data.activeDevices?.map((device, index) => (
                  <p key={index}>{device.name}</p>
                ))}
              </td>

              <td>
                {data.activeDevices?.map((device, index) => (
                  <p key={index}>{device.id}</p>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TSDLicenseDetail;

import getAxiosInstance from "../Axios/axios";

const instance = getAxiosInstance("3")

const API_KEY = "liErLyguNEOLOwPOLINIteRFloGAgEackWaRSONiaHLocrECTa"
const headers = {
    ApiKey: API_KEY
}

export const IikoAPI = {
    async getLicense() {
        return await instance.get(`license`, { headers })
            .then((res) => {
                return res.data
            })
    },
    async createLicense(data) {
        return await instance.patch(`license/${data.uid}`, data, { headers })
            .then((res) => {
                return res.data
            })
    },
    async getOrg() {
        return await instance.get(`organization`, { headers })
            .then((res) => {
                return res.data
            })
    },
    async createOrg(data) {
        return await instance.post(`organization`, data, { headers })
            .then((res) => {
                return res.data
            })
    },
    async getLogs(page, size) {
        return await instance.get(`logs?page=${page}&size=${size}`, { headers })
            .then((res) => {
                return res.data
            })
    },
    async deactivateLicense(data) {
        return await instance.patch(`license/edit/${data.id}`, { isActive: false }, { headers })
            .then((res) => {
                return res.data
            })
    },
    async activateLicense(data) {
        return await instance.patch(`license/edit/${data.id}`, { isActive: true }, { headers })
            .then((res) => {
                return res.data
            })
    }
}
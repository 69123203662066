import './App.css';
import React from 'react';
import SideBar from './Components/SideBar/SideBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TsdTable from './Components/TSD/TsdTable';
import ArcaTable from './Components/License/ArcaTable';
import LoginModal from './Components/LoginModal';
import TSDLicenseDetail from './Components/TSD/LicenseDetail';
import TindaTable from './Components/Tinda/TindaTable';
import IikoTable from './Components/ IIko/IikoTable';
import MainPage from './Components/MainPage/MainPage';
import IikoLogsTable from './Components/ IIko/IikoLogsTable';

export const App = () => {
    return (
        <div className="App">
            <BrowserRouter>
                <div className="main">
                    <SideBar />
                    <LoginModal />
                    <div className="rigth_w">
                        <Routes>
                            <>
                                <Route path="/tsd" Component={TsdTable} />
                                <Route path="/arca" Component={ArcaTable} />
                                <Route exact path="/tsd-detail/:username" Component={TSDLicenseDetail} />
                                <Route path="/tinda" Component={TindaTable} />
                                <Route path="/iiko" Component={IikoTable} />
                                <Route exact path="/iiko-logs" Component={IikoLogsTable}/>
                                <Route path="/" Component={MainPage} />
                            </>
                        </Routes>
                    </div>
                </div>
            </BrowserRouter>
        </div>
    );
};

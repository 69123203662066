import React from 'react';

const MainPage = () => {
  const serverData = [
    {
      host: 'https://bapi.4dev.kz/',
      serverName: 'Gosu TSD',
      administrator: 'Иван Деришев',
    },
    {
      host: 'https://tinda.4dev.kz/',
      serverName: 'Tinda',
      administrator: 'Сергей Щеголихин',
    },
    {
        host: 'https://license.4dev.kz/',
        serverName: 'Arca driver',
        administrator: 'Сергей Щеголихин',
    },
    {
        host: 'https://api.lm.gosu.kz/',
        serverName: 'Iiko driver',
        administrator: 'Никита Черный',
    },
  ];

  return (
    <div className="main-container">
      <h1>Лицензирование</h1>
      {serverData.map((server, index) => (
        <div key={index} className="server-card">
          <h2>Сервер {index + 1}</h2>
          <p><strong>Хост:</strong> {server.host}</p>
          <p><strong>Сервер:</strong> {server.serverName}</p>
          <p><strong>Администратор:</strong> {server.administrator}</p>
        </div>
      ))}
    </div>
  );
}

export default MainPage;

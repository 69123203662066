import getAxiosInstance from "../Axios/axios";

const instance = getAxiosInstance("2");

const BASIC_USERNAME = "Nur";
const BASIC_PASSWORD = "Arman";

export const LicenseAPI = {
    async getLicense() {
        return await instance.get(`allLicences`, {
            auth: {
                username: BASIC_USERNAME,
                password: BASIC_PASSWORD
            }
        })
            .then((res) => {
                return res.data.details
            });
    },
    async createLicense(data) {
        return await instance.post(`createLicences?mac_address=${data.mac}&license_key=${data.key}&license_date=${data.date}`, {}, {
            auth: {
                username: BASIC_USERNAME,
                password: BASIC_PASSWORD
            }
        })
            .then((res) => {
                return res.data.status_code
            })
    },
    async deleteLicense(data) {
        return await instance.get(`unreqisterLicense?maccadress=${data.mac}`, {
            auth: {
                username: BASIC_USERNAME,
                password: BASIC_PASSWORD
            }
        })
            .then((res) => {
                return res.data
            })
    },
    async setBin(data) {
        return await instance.patch(`setBIN?mac_address=${data.mac}&bin=${data.bin}`, null, {
            auth: {
                username: BASIC_USERNAME,
                password: BASIC_PASSWORD
            }
        })
            .then((res) => {
                return res.data
            })
    },
    async setOrg(data) {
        return await instance.patch(`setOrg?mac_address=${data.mac}&org=${data.org}`, null, {
            auth: {
                username: BASIC_USERNAME,
                password: BASIC_PASSWORD
            }
        })
            .then((res) => {
                return res.data
            })
    },
    async setExpireDate(data) {
        return await instance.patch(`setExDate?mac_address=${data.mac}&ExpireDate=${data.expireDate}`, null, {
            auth: {
                username: BASIC_USERNAME,
                password: BASIC_PASSWORD
            }
        })
            .then((res) => {
                return res.data
            })
    },
    async active(data) {
        return await instance.patch(`setActive?mac_address=${data.mac}`, null, {
            auth: {
                username: BASIC_USERNAME,
                password: BASIC_PASSWORD
            }
        })
            .then((res) => {
                return res.data
            })
    }
}

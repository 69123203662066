import { useEffect, useState } from "react"
import Popup from "reactjs-popup";
import { LiaUserPlusSolid, LiaKeySolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";
import { IikoAPI } from "../../Api/iikoAPI";

const ModalButtonLicenseIiko = () => {
    const initData = {
        uid: "",
        title: "",
        count: 0,
        product_name: "",
        product_sub_name: ""
    }

    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);

    const [isOpen, setIsOpen] = useState(false)
    const [formData, setFormData] = useState(initData)

    useEffect(() => {
        getFetchOrgs()
    }, [])

    const getFetchOrgs = async () => {
        try {
            const res = await IikoAPI.getOrg()
            setOrganizations(res)
        } catch (error) {
            console.log(error)
        }
    }

    const closeModal = () => {
        setIsOpen(false)
        setFormData(initData)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        IikoAPI.createLicense(formData)
        console.log(formData)
        closeModal()
    }

    const handleSelect = (id, name) => {
        setSelectedOrganization({ id, name });
        setFormData({...formData, uid: id})
    }

    return (
        <div>
            <Popup
                trigger={
                    <button className="button_add ">
                        <LiaUserPlusSolid className="btn_logo" />
                    </button>
                }
                nested
                modal
            >
                {(close) => (
                    <div className="modall addUser arka">
                        <AiOutlineClose color="white" onClick={close} className="closeB" />
                        <form className="new_user" onSubmit={handleSubmit}>
                            <h4>Новая лицензия</h4>
                            <label>Выберите организацию:</label>
                            <div className="custom-dropdown">
                                <div id="selected-item">{selectedOrganization ? selectedOrganization.name : 'Select Organization'}</div>
                                <div className="dropdown-content">
                                    {organizations.map(org => (
                                        <a key={org.id} href="#" onClick={() => handleSelect(org.id, org.name)}>
                                            {org.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <label>Title:</label>
                            <input type="text" name="title" value={formData.title} onChange={handleInputChange} />
                            <label>Count:</label>
                            <input type="number" name="count" value={formData.count} onChange={handleInputChange} />
                            <label>Product Name:</label>
                            <input type="text" name="product_name" value={formData.product_name} onChange={handleInputChange} />
                            <label>Product Sub Name:</label>
                            <input type="text" name="product_sub_name" value={formData.product_sub_name} onChange={handleInputChange} />
                            <button type="sumbit" className="sumbit">
                                Сохранить
                            </button>
                        </form>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default ModalButtonLicenseIiko
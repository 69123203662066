import { useEffect, useState } from "react";
import { TindaApi } from "../../Api/TindaApi";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";
import ModalButtonTinda from "./TindaModal";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { TbEdit } from "react-icons/tb";
import { TbReload } from "react-icons/tb";
import { FiSave } from "react-icons/fi";
import CustomTooltip from "../Hints/CustomTooltip";

const TindaTable = () => {
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(18);

  const [searchQuery, setSearchQuery] = useState("");

  const [editingOrg, setEditingOrg] = useState(null);
  const [editingBin, setEditingBin] = useState(null);
  const [editingExpireDate, setEditingExpireDate] = useState(null)

  const [orgValue, setOrgValue] = useState('');
  const [binValue, setBinValue] = useState('');
  const [expireDateValue, setExpireDateValue] = useState('')

  useEffect(() => {
    getFetchData();
  }, []);

  const getFetchData = async () => {
    try {
      const res = await TindaApi.getUsers();
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOrgButtonClick = (id) => {
    setEditingOrg(id);
    setOrgValue(data.find(item => item._Id === id).org);
  };

  const handleExpireButtonClick = (id) => {
    setEditingExpireDate(id)
    setExpireDateValue(data.find(item => item._Id === id).expireDate)
  }

  const handleBinButtonClick = (id) => {
    setEditingBin(id);
    setBinValue(data.find(item => item._Id === id).bin);
  };

  const handleSaveOrg = async (id) => {
    try {
      await TindaApi.setOrg({ id, org: orgValue })

      setEditingOrg(null)
    } catch (error) {
      console.log('Ошибка при изменении org:', error)
    }
  }

  const handleSaveBin = async (id) => {
    try {
      await TindaApi.setBin({ id, bin: binValue })

      setEditingBin(null)
    } catch (error) {
      console.log('Ошибка при изменении org:', error)
    }
  }

  const handleSaveExpireDate = async (id) => {
    try {
      await TindaApi.setExpireDate({ id, expireDate: expireDateValue })

      setEditingExpireDate(null)
    } catch (error) {
      console.log('Ошибка при изменении org:', error)
    }
  }


  const handleDeactivateLicense = async (id) => {
    try {
      const confurmed = window.confirm(
        "Вы уверены, что хотите деактивировать лицензию?"
      );
      if (confurmed) {
        await TindaApi.setActive({ id });
        getFetchData();
      }
    } catch (error) {
      console.error("Error deleting license:", error);
    }
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleActivateLicense = async (id) => {
    try {
      const confurmed = window.confirm(
        "Вы уверены, что хотите Aктивировать лицензию?"
      );
      if (confurmed) {
        await TindaApi.setActive({ id });
        getFetchData();
      }
    } catch (error) {
      console.error("Error deleting license:", error);
    }
  };

  const filteredData = data.filter(
    (license) =>
      license.login.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      license.org.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="licenseTable">
      <div className="table_option">
        <input
          className="search-input"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Поиск..."
        />
        <div className="table_option_right">
          <div className="items_per_page">
            Лицензий на страницу:
            <select
              className="items_count"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={18}>18</option>
              <option value={36}>36</option>
              <option value={54}>54</option>
            </select>
          </div>
          <CustomTooltip text='Создание лицензии'>
            <ModalButtonTinda />
          </CustomTooltip>
          <TbReload
            onClick={() => { getFetchData() }}
          />
        </div>
      </div>
      <div className="cards table_body">
        {currentItems.map((item, index) => (
          <Form className="card" key={index}>
            <table className="user_details_table">
              <tbody>
                <tr>
                  <td>
                    <a>ID</a>
                  </td>
                  <td>
                    <p className="value">{item._Id ? item._Id : "-"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Login</p>
                  </td>
                  <td>
                    <p className="value">{item.login ? item.login : "-"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Organizaztion</p>
                  </td>
                  <td>
                    {editingOrg === item._Id ? (
                      <>
                        <input type="text" value={orgValue} onChange={(e) => setOrgValue(e.target.value)} />
                        <button onClick={(e) => {
                          e.preventDefault()
                          handleSaveOrg(item._Id)
                        }}>
                          <FiSave />
                        </button>
                      </>
                    ) : (
                      <>
                        <span>{item.org || "N/A"}</span>
                        <button onClick={(e) => {
                          e.preventDefault()
                          handleOrgButtonClick(item._Id)
                        }}>
                          <TbEdit />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>BIN</p>
                  </td>
                  <td>
                    {editingBin === item._Id ? (
                      <>
                        <input type="text" value={binValue} onChange={(e) => setBinValue(e.target.value)} />
                        <button onClick={(e) => {
                          e.preventDefault();
                          handleSaveBin(item._Id)
                        }}>
                          <FiSave />
                        </button>
                      </>
                    ) : (
                      <>
                        <span>{item.bin || "N/A"}</span>
                        <button onClick={(e) => {
                          e.preventDefault();
                          handleBinButtonClick(item._Id)
                        }}>
                          <TbEdit />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Create Date</p>
                  </td>
                  <td>
                    <p className="value">
                      {item.createDate ? item.createDate : "-"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Expire Date</p>
                  </td>
                  <td>
                    {/* <p className="value">
                      {moment(item.expireDate).format("DD.MM.YYYY") ===
                        "Invalid date"
                        ? item.expireDate
                        : moment(item.expireDate).format("DD.MM.YYYY")}
                    </p> */}
                    {editingExpireDate === item._Id ? (
                      <>
                        <input type="date" value={expireDateValue} onChange={(e) => setExpireDateValue(e.target.value)} />
                        <button onClick={(e) => {
                          e.preventDefault();
                          handleSaveExpireDate(item._Id)
                        }}>
                          <FiSave />
                        </button>
                      </>
                    ) : (
                      <>
                        <span>{moment(item.expireDate).format("DD.MM.YYYY") || "N/A"}</span>
                        <button onClick={(e) => {
                          e.preventDefault();
                          handleExpireButtonClick(item._Id)
                        }}>
                          <TbEdit />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Status</p>
                  </td>
                  <td>
                    <div
                      className={`statusChangeBtn ${item.isActive ? "ActiveSBtn" : "DeactiveSBtn"}`}
                      onClick={() => item.isActive ? handleDeactivateLicense(item._Id) : handleActivateLicense(item._Id)}
                    >

                      {item.isActive ? "Active" : "Deactive"}
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
        ))}
      </div>
      <div className="pagination">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LiaAngleLeftSolid />
        </button>
        {Array.from({
          length: Math.ceil(data.length / itemsPerPage),
        }).map((_, page) => (
          <span
            key={page}
            onClick={() => goToPage(page + 1)}
            className={currentPage === page + 1 ? "active" : ""}
          >
            {page + 1}
          </span>
        ))}
        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        >
          <LiaAngleRightSolid />
        </button>
      </div>

    </div>
  );
};

export default TindaTable;

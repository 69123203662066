import getAxiosInstance from "../Axios/axios";

const instance = getAxiosInstance("4")

const data = {
    username: "tindaadmin",
    password: "vbnJ456#"
}

async function getJwtToken() {
    try {
        const res = await instance.post(`login`, data);
        return res.data
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const TindaApi = {
    async getUsers() {
        try {
            const token = await getJwtToken()
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await instance.get(`Admin/GetUsers`, { headers })
            return response.data
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    async createUser(data) {
        try {
            const token = await getJwtToken()
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await instance.post(`Register`, data, { headers })
            return response.data
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    async setOrg(data) {
        try {
            const token = await getJwtToken()
            const headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await instance.patch(
                `Admin/SetOrg/${data.id}?Org=${data.org}`, null, { headers }
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    async setActive(data) {
        try {
            const token = await getJwtToken()
            const headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await instance.patch(
                `Admin/SetActive/${data.id}`, null, { headers }
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error;
        }
    },
    async setBin(data) {
        try {
            const token = await getJwtToken()
            const headers = {
                Authorization: `Bearer ${token}`
            }
            const response = await instance.patch(
                `Admin/SetBIN/${data.id}?bin=${data.bin}`, null, { headers }
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    async setExpireDate(data) {
        try {
            const token = await getJwtToken()
            const headers = {
                Authorization: `Bearer ${token}`
            }

            const formattedExpireDate = new Date(data.expireDate);
            const formattedDateString = `${formattedExpireDate.getFullYear()}-${(formattedExpireDate.getMonth() + 1)
                .toString()
                .padStart(2, '0')}-${formattedExpireDate.getDate().toString().padStart(2, '0')}T${formattedExpireDate
                    .toTimeString()
                    .slice(0, 8)}`;

            const response = await instance.patch(
                `Admin/SetExpireDate/${data.id}&date=${formattedDateString}`, null, { headers }
            )
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
}


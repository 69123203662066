import axios from "axios";

const GOSU_TSD_URL = "https://bapi.4dev.kz/"
const ARCA_URL = "https://license.4dev.kz/"
const IIKO_URL = "https://api.lm.gosu.kz/"
const TINDA_URL = "https://tinda.4dev.kz/"

function getAxiosInstance(urlType) {
    let baseURL = "";
  
    if (urlType === "1") {
      baseURL = GOSU_TSD_URL
    } else if (urlType === "2") {
      baseURL = ARCA_URL
    } else if (urlType === "3") {
      baseURL = IIKO_URL
    } else if (urlType == "4") {
      baseURL = TINDA_URL
    } else {
      throw new Error("Invalid URL type")
    }
  
    const instance = axios.create({
      baseURL: baseURL
    });
  
    return instance;
}

export default getAxiosInstance;
import React, { useState, useEffect } from "react";
import { IikoAPI } from "../../Api/iikoAPI";

const IikoLogsTable = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    useEffect(() => {
        getFetchData();
        const interval = setInterval(() => getFetchData(), 5000);

        return () => {
            clearInterval(interval);
        };
    }, [currentPage, itemsPerPage]);

    const getFetchData = async () => {
        try {
            const res = await IikoAPI.getLogs(currentPage, itemsPerPage);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    };

    const nextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const changeItemsPerPage = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(0); 
    };

    const tableRowStyle = {
        borderBottom: '1px solid #ddd',
    };

    const tableCellStyle = {
        padding: '8px',
        borderBottom: '1px solid #ddd',
    };

    return (
        <div className="licenseTable">
            <div className="table_option">
                <div className="table_option_right">
                    <div className="items_per_page">
                        Логов на страницу:
                        <select
                            className="items_count"
                            value={itemsPerPage}
                            onChange={changeItemsPerPage}
                        >
                            <option value={20}>20</option>
                            <option value={60}>60</option>
                            <option value={180}>180</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className=" table_body">
                <div style={{ overflowX: 'auto', width: '100%', minWidth: '800px' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%', whiteSpace: 'nowrap' }}>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>DeviceId</th>
                                <th>DeviceName</th>
                                <th>Type</th>
                                <th>ProductName</th>
                                <th>ProductSubName</th>
                                <th>DateTime</th>
                                <th>AdditionalData</th>
                                <th>Request</th>
                                <th>Response</th>
                                <th>Error</th>
                                <th>OrganizationId</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data?.map(item => (
                                <tr key={item.id} style={tableRowStyle}>
                                    <td style={tableCellStyle}>{item.id}</td>
                                    <td style={tableCellStyle}>{item.deviceId}</td>
                                    <td style={tableCellStyle}>{item.deviceName}</td>
                                    <td style={tableCellStyle}>{item.type}</td>
                                    <td style={tableCellStyle}>{item.productName}</td>
                                    <td style={tableCellStyle}>{item.productSubName}</td>
                                    <td style={tableCellStyle}>{item.dateTime}</td>
                                    <td style={tableCellStyle}>{item.additionalData}</td>
                                    <td style={tableCellStyle}>{item.request && JSON.stringify(JSON.parse(item.request), null, 2)}</td>
                                    <td style={tableCellStyle}>{item.response && JSON.stringify(JSON.parse(item.response), null, 2)}</td>
                                    <td style={tableCellStyle}>{item.error}</td>
                                    <td style={tableCellStyle}>{item.organizationId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 0}>Previous</button>
                <span>{currentPage + 1}</span>
                <button onClick={nextPage} >Next</button>
            </div>
        </div>
    );
}

export default IikoLogsTable;

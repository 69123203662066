import React, { useState } from "react";
import { LicenseAPI } from "../../Api/LicenseApi";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineClose } from "react-icons/ai";
import Popup from "reactjs-popup";
import { LiaUserPlusSolid, LiaKeySolid } from "react-icons/lia";
import Tippy from '@tippyjs/react';

const ModalButtonArca = () => {
  const initialData = {
    mac: "",
    key: "",
    date: "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initialData);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormData(initialData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateLicenseKey = () => {
    const generatedKey = uuidv4();
    setFormData((prevData) => ({
      ...prevData,
      key: generatedKey,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    LicenseAPI.createLicense(formData);
    closeModal();
  };

  return (
    <div>
      <Popup
        trigger={
          <button className="button_add ">
            <LiaUserPlusSolid className="btn_logo" />
          </button>
        }
        nested
        modal
      >
        {(close) => (
          <div className="modall addUser arka">
            <AiOutlineClose color="white" onClick={close} className="closeB" />
            <form className="new_user" onSubmit={handleSubmit}>
              <h4>Новый пользователь</h4>
              <label>Mac-адрес:</label>
              <input
                type="text"
                name="mac"
                value={formData.mac}
                onChange={handleInputChange}
              />
              <label className="input-lable-change-password">
                Лицензионный ключ:
              </label>
              <div className="generate_form">
                <input
                  type="text"
                  name="key"
                  value={formData.key}
                  onChange={handleInputChange}
                />
                <Tippy 
                  interactive={true} interactiveBorder={20} delay={100}
                  content={<div className="popcard">Сгнерировать ключ</div>}>
                  <button className="gen_key" onClick={generateLicenseKey}>
                  <LiaKeySolid  />
                  </button>
                </Tippy>
              </div>
              <label>Дата:</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
              <button type="submit" className="sumbit">Сохранить</button>
            </form>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ModalButtonArca;
